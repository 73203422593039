<template lang="pug">
main.contact
	display-header(:img="require('@/assets/img/real_site_images/resized/reception_cropped.jpg')",headline="About Us")

	//- About Area
	main
		a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
			img(src='../assets/img/WhatsApp.svg')
		article.single-journal-entry
			.entry-content
				.container(tyle="text-align:center; width:100%; margin:auto") Blending modern and cultural sophistication, Time Square's Accommodation reflect the art of Calm and design, with all the comforts of home along with the best technology. Almost all the rooms offer a captivating sight of Time Square's interior along with its frescos and precious mosaic floors. All the accommodations provide the best exclusive services and many more.
				.container
					figure.entry-image_1
						img.img-responsive(src="../assets/img/real_site_images/resized/lobby_cropped.jpg",alt="Business Person"	,style="margin:auto;margin-bottom:10rem; aspect-ratio:3;border-radius:6px;object-fit:cover")
						img.img-responsive(src="../assets/img/real_site_images/resized/pool.jpg",alt="Pool Area"	,style="margin:auto;margin-bottom:10rem; aspect-ratio:3;border-radius:6px;object-fit:cover")
				.clearfix
	//- About Area End
	.container
		.contact-form-container
			h1 contact us

			.contact-form-contact-us

				.contact-form-drop-Message
					h2(style="margin-bottom: 30px") Drop a Message!
					p
						span Room Reservation: 
							a(href="tel:+91 79847 59430") +91-79847 59430
						br
						span Membership Inquiry:
							a(href="tel:+91 75791 75791") +91-75791 75791
						br
						span Events: 
							a(href="tel:+91 79847 60039") +91-79847 60039
						br
						a.mail-link(href="mailto:club@timesquare.in") club@timesquare.in
				
				.contact-form-visit-us
					h2(style="margin-bottom: 30px; margin-top: 20px") Visit Us

					p
					| TIME SQUARE RESORT &amp; SPA <br />
					| The Villa <br />
					| Opp. Suryavarsani School, <br />
					| Bhuj- Mundra Road, <br />
					| Village : Sedata - Bhuj - Gujarat <br />
					| Pincode : 370427
		.clearfix

</template>

<script>

import DisplayHeader from '../components/DisplayHeader.vue'

export default {
  name: "Club",
  components: {
	DisplayHeader
  },
  data() {
	let form = {
	  name: "",
	  email: "",
	  subject: "",
	  phoneNumber: "",
	  subject: "",
	};

	return { form };
  },
};
</script>

<style scoped>
.divider {
  margin-bottom: 100px;
}

@media (max-width: 800px) {

	.img-responsive{
		aspect-ratio: 1.4 !important;
	}

}

@media (max-width: 600px) {

	.contact-form-visit-us{
		display: none;
	}

}

</style>